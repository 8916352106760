import React from "react"
import "./assets/styles/_index.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import SuccessImage from "/utility/assets/successmodal/thankyou-icon.png"
import BPSImage from "/utility/assets/successmodal/BPS-icon.png"
import { XCircleFill } from "react-bootstrap-icons"

const SuccessModal = ({ closeModal }) => {
  return (
    <div className="modal modal-thankyou-mob">
      <div className="modal-content">
        <div className="close-content" style={{ alignItems: "center" }}>
          <XCircleFill
            onClick={closeModal}
            className="close"
            style={{ color: "#3d106c" }}
          />
        </div>
        <div className="modal-content-thankyou"> 
          <center>
            <img src={SuccessImage} alt="Success" />
          </center>
          <h1>Thank You!</h1>
          <p>Our team will reach out to you soon....</p>
          <Link
            className="CheckBPSScoreDesk"
            target="_blank"
            to="https://www.propex.ai/buy/profile?act=link"
          >
            Check Your BPS Score
          </Link>
        </div>

        <div className="modal-content-text">
          <Container fluid>
            <Row>
              <Col lg={2} className="textAlignLefts">
                <img src={BPSImage} alt="BPS" />
              </Col>
              <Col lg={7} className="textAlignLeft">
                <div className="CheckYourDetailsBPS">
                  <h6>Buyer Profile Score (BPS)</h6>
                  <ul className="CheckYourList">
                    <li>Choose from a wide-array Properties to buy</li>
                    <li>
                      Avail exciting discounts as a High-intent Prospect Buyer
                    </li>
                    <li>Helps you make an informed decision to buy property</li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} className="textAlignCenter p-0 CheckBPSScoreCenter">
                <Link
                  className="CheckBPSScore"
                  target="_blank"
                  to="https://www.propex.ai/buy/profile?act=link"
                >
                  Check Your BPS Score
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default SuccessModal
