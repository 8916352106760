import React, { useState, useEffect } from "react"
import "./assets/styles/_index.scss"
import InfoSidebar from "../informationdesk/InfoSidebar"
import Container from "react-bootstrap/Container"
import { ArrowRight } from "react-bootstrap-icons"
import { Accordion, Card, Col, Button, Row } from "react-bootstrap"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Link } from "gatsby"
import Slider from "react-slick"
import Image from "react-bootstrap/Image"
import EnquiryPopup from "../enquirypopup/EnquiryPopUp"
import { Formik } from "formik"
import SuccessModal from "../SuccessModal/SuccesModal"
import configs from "../../Utils/configs"
import { postAPICall } from "../../Utils/Network"
import LoadingModal from "../loading/LoadingModal"

function InformationDesk() {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true)
  const [isOpenSV, setIsOpenSV] = useState(false)
  const [isOpenEN, setIsOpenEN] = useState(false)
  const [hoveredButton, setHoveredButton] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [developers, setDevelopers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)

  const HomeIcon = configs.UTILITY_URL + "/informationdesk/Home_Icon.png"
  const ExpoIcon = configs.UTILITY_URL + "/informationdesk/Expo_Icon.png"
  const enquirynow =
    configs.UTILITY_URL + "/informationdesk/icons/enquirynow.png"
  const enquirylogo =
    configs.UTILITY_URL + "/informationdesk/icons/EnquiryLogo.png"
  const backgroundImage =
    configs.UTILITY_URL + "/informationdesk/Infodesk-bg.png"
  const backgroundMobileImage =
    configs.UTILITY_URL + "/informationdesk/Infodesk-bg-mob.png"

  useEffect(() => {
    FetchDetails()
    const checkScreenSize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    checkScreenSize()

    window.addEventListener("resize", checkScreenSize)
    setIsInitialized(true)
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [isMobile])

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL()
    const param = {
      DeveloperName: "",
    }

    postAPICall(url, param, res => {
      // // console.log(res.data.Status)
      if (res && res.Status === "success") {
        if (res && res.Data && res.Data.DeveloperDetails) {
          const hallImages = res.Data.HallImages
          const sortedImages = hallImages.sort(
            (a, b) => a.SortOrder - b.SortOrder
          )
          const developerImages = sortedImages.map(image => ({
            src: `${configs.UTILITY_URL}/${image.DeveloperId}/${image.FileName}`,
            link: `/developers/${image.RouteName}/`,
          }))

          setDevelopers(developerImages)
        }
        setIsLoading(false)
      }
    })
  }

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(isRightSidebarOpen)
  }

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore)
  }

  const handleButtonHover = buttonName => {
    setHoveredButton(buttonName)
  }

  const handleButtonLeave = () => {
    setHoveredButton(null)
  }

  const togglePopupSV = () => {
    setIsOpenSV(!isOpenSV)
  }

  const togglePopupEN = () => {
    setIsOpenEN(!isOpenEN)
  }

  const closeModal = () => {
    setShowSuccessModal(false)
  }

  //Enquiry API_REQUEST
  const EnquiryFormSubmit = async values => {
    // console.log(values)
    var config = { "Access-Control-Allow-Origin": "*" }
    var param = {
      Name: values.fullname,
      Email: values.email,
      Phone: values.phonenumber,
      Location: "",
      Budget: "",
      PickupDate: "",
      PickupTimeId: "",
      LeadSource: configs.LEAD_SOURCE_ENQUIRY,
      LandingPageId: configs.LANDING_PAGE_ID,
      PlatFormId: configs.PLATFORM_ID,
      IsIgnoreCRM: false,
    }
    var url = configs.ENQUIRY_API_URL()

    postAPICall(
      url,
      param,
      config,
      res => {
        // // console.log(res.data);
        setIsOpenEN(false)
        setShowSuccessModal(true)
      },
      err => {
        // // console.log(err);
      }
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
    ],
  }

  const BackgroundImage = {
    backgroundImage: isMobile
      ? `url(${backgroundMobileImage})`
      : `url(${backgroundImage})`,
    height: "100vh",
  }

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <LoadingModal isLoading={isLoading} />

      <div className="InformationDeskSection" style={BackgroundImage}>
        <div className="d-none d-md-block">
          <InfoSidebar
            isOpen={isRightSidebarOpen}
            toggleRightSidebar={toggleRightSidebar}
          />
        </div>

        <Accordion
          defaultActiveKey="0"
          className="d-block d-md-none ProjectListAccordion ProjectListAccordionInfo ProjectDetailsList"
        >
          <Card>
          <Card.Header>
  <Accordion.Toggle as={Button} variant="" eventKey="0" style={{ color: "white",fontSize:"17px" }}>
    Info desk
  </Accordion.Toggle>
</Card.Header>


            <Accordion.Collapse eventKey="0">
              <Card.Body style={{ height: "400px", overflowY: "auto" }}>
                <Tabs
                  defaultActiveKey="aboutprop"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="aboutprop" title="About Propverse">
                    <div className="ReadMoreSection">
                      <h4 className="m-0">Info desk</h4>
                      <p className="mb-0">
                        <p className="AboutExpoPara">
                          Reinventing Real Estate, through virtual experiences.
                        </p>

                        <p className="AboutExpoPara">
                          PropEx.ai India’s 1st property exchange presents
                          PropVerse!
                        </p>

                        <p className="AboutExpoPara">
                          PropVerse redefines property expos by magnifying the
                          expo experience virtually & brings global reach to
                          your fingertips.
                        </p>
                      </p>

                      {isShowMore && (
                        <p className="mb-0">
                          <p className="AboutExpoPara">
                            PropVerse is an exceptional showcase of hand-picked
                            top developers & property buyers can discover new
                            projects from an extensive collection of properties
                            and network with the important stakeholders of the
                            real estate ecosystem.
                          </p>
                          {/* //     <p className="AboutExpoPara">PropEx.ai India’s 1st property exchange presents PropVerse!</p>

                                                //     <p className="AboutExpoPara">PropVerse redefines property expos by magnifying the expo experience virtually & brings global reach to your fingertips.</p>

                                                //     <p className="AboutExpoPara">PropVerse is an exceptional showcase of hand-picked top developers & property buyers can discover new projects from an extensive collection of properties and network with the important stakeholders of the real estate ecosystem.</p> */}
                        </p>
                      )}
                      <p
                        className="m-0 AboutExpoButton"
                        onClick={toggleReadMoreLess}
                      >
                        {isShowMore ? "Read Less" : "Read More"} <ArrowRight />
                      </p>

                      <div className="infoRatingSection infoRatingSectionMob">
                        <Container>
                          <Row lg={12} className="infoRatingSectionWrap">
                            <Col lg={4} className="infoRatingSectionGrid">
                              <h5 className="textNumber infoRatingSectionText">
                                20
                              </h5>
                              <p>Top Developers</p>
                            </Col>
                            <Col lg={4} className="infoRatingSectionGrid">
                              <h5 className="textNumber infoRatingSectionText">
                                70
                              </h5>
                              <p>Exclusive Projects</p>
                            </Col>
                            <Col lg={4} className="infoRatingSectionGrid">
                              <h5 className="textNumber infoRatingSectionText">
                                300 +
                              </h5>
                              <p>PropExperts</p>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="featured" title="Featured Developers">
                    <section className="pt-0">
                      <Container className="p-0">
                        <Slider {...settings} className="">
                          {developers.map(card => (
                            <div className="ProjectListAccordionSlider">
                              <Row className="m-0">
                                <div className="ProjectListAccordionLink">
                                  <Link to={card.link}>
                                    <Image
                                      src={card.src}
                                      fluid
                                      className=""
                                      alt="image"
                                    />
                                  </Link>
                                </div>
                              </Row>
                            </div>
                          ))}
                        </Slider>
                      </Container>
                    </section>
                  </Tab>
                  <Tab eventKey="bps" title="BPS">
                    <section className="pt-0">
                      <div className="CheckYourSectionMob">
                        <Link
                          target="_blank"
                          to="https://www.propex.ai/buy/profile?act=link"
                        >
                          <Button>Check Your BPS Score</Button>
                        </Link>
                      </div>

                      <Container className="p-0">
                        <div className="CheckYourDetailsBPS">
                          <h6>Buyer Profile Score (BPS)</h6>
                          <ul className="CheckYourList">
                            <li>Choose from a wide-array Properties to buy</li>
                            <li>
                              Avail exciting discounts as a High-intent Prospect
                              Buyer
                            </li>
                            <li>
                              Helps you make an informed decision to buy
                              property
                            </li>
                          </ul>
                        </div>
                      </Container>
                    </section>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>

      <Container fluid className="ProjectAboutMiddleSectionTop">
        <div className="ProjectTopSectionInfo">
          <ul>
            <li>
              <Link to="/">
                <Image src={HomeIcon} fluid className="homeicon" alt="image" />
                <br />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/exhibition-hall/">
                <Image src={ExpoIcon} fluid className="hallicon" alt="image" />
                <br />
                <span>Hall</span>
              </Link>
            </li>
            {/* <li>
                            <Link to="/information-desk/">
                                <Image src={InfodeskIcon} fluid className="infoicon" /><br /><span>Info</span>
                            </Link>
                        </li> */}
          </ul>
        </div>
      </Container>
      {!isMobile && (
        <Container fluid className="ProjectAboutMiddleSection">
          <div className="Project-enquirynow">
            <Link to="#">
              {/* <Image
             
              onClick={togglePopupEN}
              src={enquirynow}
              fluid
              //className="d-none"
              alt="image"
            /> */}
              <div className="tooltips">
                <Image
                  src={enquirynow}
                  onClick={togglePopupEN}
                  onMouseEnter={() => handleButtonHover("Enquiry Now")}
                  onMouseLeave={handleButtonLeave}
                  fluid
                  className=""
                  alt="image"
                />
                <span className="tooltiptext">Enquiry Now</span>
              </div>
            </Link>
          </div>
        </Container>
      )}
      {isOpenEN && (
        <EnquiryPopup
          content={
            <>
              <Container>
                <Row>
                  <Col className="enquiry-image-col" lg={6} md={6} sm={6}>
                    <Image src={enquirylogo} alt="image" />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="enquiry-content-text">
                    <Formik
                      initialValues={{
                        fullname: "",
                        email: "",
                        phonenumber: "",
                        // selectedOption: "",
                      }}
                      validate={values => {
                        const errors = {}
                        if (!values.fullname) {
                          errors.fullname = "Required"
                        } else if (!values.phonenumber) {
                          errors.phonenumber = "Required"
                        } else if (!values.email) {
                          errors.email = "Required"
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address"
                        }
                        // else if (!values.selectedOption) {
                        //   errors.selectedOption = "Atleast Select One"
                        // }
                        return errors
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          EnquiryFormSubmit(values)

                          setSubmitting(false)
                        }, 400)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="popup">
                            <h4 className="popup-header">Let's Connect</h4>
                            <Container className="p-0">
                              <Row className="w-100 m-0">
                                <div className="position-relative">
                                  <input
                                    className="Popup-input"
                                    type="text"
                                    name="fullname"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                    placeholder="Full Name"
                                  />
                                  <span className="error-text">
                                    {errors.fullname &&
                                      touched.fullname &&
                                      errors.fullname}
                                  </span>
                                </div>

                                <div className="position-relative">
                                  <input
                                    className="Popup-input"
                                    type="tel"
                                    name="phonenumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phonenumber}
                                    placeholder="Phone Number"
                                    maxLength={10}
                                  />
                                  <span className="error-text">
                                    {errors.phonenumber &&
                                      touched.phonenumber &&
                                      errors.phonenumber}
                                  </span>
                                </div>

                                <div className="position-relative">
                                  <input
                                    className="Popup-input"
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder="E-mail Id"
                                  />
                                  <span className="error-text">
                                    {errors.email &&
                                      touched.email &&
                                      errors.email}
                                  </span>
                                </div>
                                <div className="w-100">
                                  <Button
                                    type="submit"
                                    className="Popup-submit"
                                    disabled={isSubmitting}
                                  >
                                    Book Now
                                  </Button>
                                </div>
                              </Row>
                            </Container>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Container>
            </>
          }
          handleClose={togglePopupEN}
        ></EnquiryPopup>
      )}

      {showSuccessModal && <SuccessModal closeModal={closeModal} />}
    </>
  )
}

export default InformationDesk
