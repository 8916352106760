import React, { useEffect, useState } from 'react';
import "./assets/styles/_index.scss"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from "gatsby";
import InfoReadMore from "../informationdesk/InfoReadMore"
import Slider from "react-slick"
import configs from '../../Utils/configs';
import { postAPICall } from '../../Utils/Network';
const prevArrow = configs.UTILITY_URL + "/utility/assets/informationdesk/prevArrow.png";
const nextArrow = configs.UTILITY_URL + "/utility/assets/informationdesk/nextArrow.png";


const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${prevArrow})` }}
      onClick={onClick}
    />
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${nextArrow})` }}
      onClick={onClick}
    />
  );
}

const InfoSidebar = ({ isOpen, toggleRightSidebar }) => {
  const [developers,setDevelopers]=useState([])

  
  useEffect(()=>{
    FetchDetails();
   
 },[])

const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL()
    const param = {
      DeveloperName: "",
    }

    postAPICall(
      url,
      param,
      res => {
       // // console.log(res.Status)

        if (res && res.Data && res.Data.DeveloperDetails) {
         
            const hallImages = res.Data.HallImages;
            const sortedImages = hallImages.sort(
              (a, b) => a.SortOrder - b.SortOrder
            )
            const developerImages = sortedImages.map(image => ({
              src: `${configs.UTILITY_URL}/${image.DeveloperId}/${image.FileName}`,
              link: `/developers/${image.RouteName}/`,
            }))

             setDevelopers(developerImages)
          }
        }
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },

    ],

  }

  
  return (
    <div className={`right-sidebar ${isOpen ? 'open' : ''}`}>

      <div className="infoBackButton" onClick={toggleRightSidebar}><span>
        <Link to="/"><ArrowLeft /> Back</Link></span>
      </div>

      <div className="infoAboutExpo">
        <InfoReadMore />
      </div>

      <div className="infoRatingSection">
        <Container>
          <Row lg={12} className="infoRatingSectionWrap">
            <Col lg={4} className="infoRatingSectionGrid">
              <h5 className="textNumber infoRatingSectionText">20</h5>
              <p>Top Developers</p>
            </Col>
            <Col lg={4} className="infoRatingSectionGrid">
              <h5 className="textNumber infoRatingSectionText">70</h5>
              <p>Exclusive Projects</p>
            </Col>
            <Col lg={4} className="infoRatingSectionGrid">
              <h5 className="textNumber infoRatingSectionText">300 +</h5>
              <p>PropExperts</p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="infoFeaturedSection">
        <h6>Featured Developers</h6>

        <Row>
          <section className="Rooms section-white pt-0">
            <Container>
              <Slider {...settings} className="rooms-blues-slider">
                {developers.map((card) => (
                  <div className="ProjectListAccordionSlider">
                    <Row className="m-0">
                      <div className="ProjectListAccordionLink">
                        <Link to={card.link}><Image src={card.src} fluid className="" alt="image"/></Link>
                      </div>
                    </Row>
                  </div>
                ))}
              </Slider>
            </Container>

          </section>
        </Row>
      </div>

      <div className="infoHallSection">
        <Link to="/exhibition-hall/"><Button>Go to Exhibition Hall</Button></Link>
      </div>

      <div className="CheckYourDetails">
        <h6>Buyer Profile Score (BPS)</h6>
        <ul className="CheckYourList">
          <li>Choose from a wide-array Properties to buy</li>
          <li>Avail exciting discounts as a High-intent Prospect Buyer</li>
          <li>Helps you make an informed decision to buy property</li>
        </ul>
      </div>

      <div className="CheckYourSection">
        <Link target="_blank" to="https://www.propex.ai/buy/profile?act=link"><Button>Check Your BPS Score</Button></Link>
      </div>

      {/* <div className='PropexLogo'>
        <Link to="https://www.propex.ai/" target='_blank'><Image src={PropexLogo} fluid className="" /></Link>
      </div> */}
    </div>
  );
};

export default InfoSidebar;
